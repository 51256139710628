import Modal from '@mui/material/Modal';
import React, { ReactNode } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  showCloseButton?: boolean;
  closeButtonColor?: string;
  bgColor?: string;
  canClickOutside?: boolean;
  isFullHeight?: boolean;
  showScrollBar?: boolean;
  isWidthAlwayAuto?: boolean;
  isNoShadow?: boolean;
  preventDefault?: boolean;
  styleClass?: string;
  closeButtonClass?: string;
  className?: string;
  isNoPadding?: boolean;
  width?: string;
}

export default function CustomModal({
  open,
  onClose,
  children,
  showCloseButton,
  closeButtonColor,
  bgColor,
  canClickOutside,
  isFullHeight,
  showScrollBar,
  isWidthAlwayAuto,
  isNoShadow,
  preventDefault,
  styleClass,
  closeButtonClass,
  className,
  isNoPadding,
  width,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={(e: any) => {
        e.preventDefault();
        if (canClickOutside !== false) {
          onClose();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styleClass}
    >
      <div
        onClick={
          preventDefault === true
            ? (e: any) => {
                e.preventDefault();
              }
            : undefined
        }
        className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
          bgColor ? `bg-${bgColor}` : 'bg-white'
        } ${isWidthAlwayAuto ? 'w-auto' : 'w-full'} ${
          width ? `md:w-${width}` : 'md:w-auto'
        } lg:w-auto rounded ${
          isNoShadow ? '' : 'shadow-md'
        } outline-none max-h-[95%] max-w-[95%] overflow-auto ${className}`}
      >
        <div className={`relative ${isFullHeight ? 'h-[95vh]' : ''}`}>
          {showCloseButton && (
            <div
              className={`absolute ${
                closeButtonClass ? closeButtonClass : 'top-0 right-0'
              } cursor-pointer z-50`}
              onClick={onClose}
            >
              <i
                className={`bi bi-x font-extrabold text-2xl ${
                  closeButtonColor ? `text-${closeButtonColor}` : 'text-primary'
                }`}
              ></i>
            </div>
          )}
          <div
            className={`${isNoPadding ? '' : 'py-2 px-1'} h-full ${
              showScrollBar ? 'show-scrollbar' : ''
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </Modal>
  );
}
