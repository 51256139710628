import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { shortMonth } from '@/constants/checkout.constant';
import { ProductModel, ProductModelConverter } from '../Product.model';

export enum VoucherSectionName {
  // eslint-disable-next-line no-unused-vars
  cart = 'cart',
  // eslint-disable-next-line no-unused-vars
  ship = 'ship',
}

export enum VoucherType {
  // eslint-disable-next-line no-unused-vars
  discount = 'C',
  // eslint-disable-next-line no-unused-vars
  cash = 'B',
  // eslint-disable-next-line no-unused-vars
  percent = 'J',
  // eslint-disable-next-line no-unused-vars
  partner = 'PR',
}

export enum VoucherState {
  // eslint-disable-next-line no-unused-vars
  useAble = 1, //issued,reserve
  // eslint-disable-next-line no-unused-vars
  used = 2, //redeemed
  // eslint-disable-next-line no-unused-vars
  expired = 3, //expired
  // eslint-disable-next-line no-unused-vars
  issued = 4, //issued
}

export enum VoucherStateType {
  // eslint-disable-next-line no-unused-vars
  issued = 'issued',
  // eslint-disable-next-line no-unused-vars
  reserved = 'reserved',
  // eslint-disable-next-line no-unused-vars
  used = 'reserved',
  // eslint-disable-next-line no-unused-vars
  expired = 'expired',
  // eslint-disable-next-line no-unused-vars
}

export enum VoucherSortingType {
  // eslint-disable-next-line no-unused-vars
  issuedDateAsc = 0,
  // eslint-disable-next-line no-unused-vars
  expiredDateAsc = 1,
  // eslint-disable-next-line no-unused-vars
  amountAsc = 2,
  // eslint-disable-next-line no-unused-vars
  issuedDateDesc = 3,
  // eslint-disable-next-line no-unused-vars
  expiredDateDesc = 4,
  // eslint-disable-next-line no-unused-vars
  amountDesc = 5,
  // eslint-disable-next-line no-unused-vars
  couponType = 99,
}

export enum CouponSource {
  // eslint-disable-next-line no-unused-vars
  omni = 'omni',
  // eslint-disable-next-line no-unused-vars
  camp = 'camp',
}

export interface MyVoucherModel {
  title?: string;
  sub?: string;
  type?: string;
  state?: string;
  voucherdetail?: VoucherDetail[];
  monththai?: string[];
  productpaging?: Productpaging;
  shiprewardid?: string;
  limit?: number;
  page?: number;
  srct?: null;
  sort?: VoucherSortingType;
  fill?: null;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
  lang?: string;
}

export interface Productpaging {
  total?: number;
  perPage?: number;
  currentPage?: number;
  lastPage?: number;
  from?: number;
  to?: number;
  data?: VoucherDetail[];
  skcount?: number;
}

export interface VoucherDetail {
  voucherCode?: string;
  voucherKey?: string;
  state?: VoucherStateType;
  reservedExpiresAt?: string;
  voucherStartsAt?: string;
  voucherExpiresAtTH?: string;
  voucherExpiresAtEN?: string;
  usedStoreName?: string;
  usedRef?: string;
  usedDateTH?: string;
  usedDateEN?: string;
  posRef?: string;
  issuedAt?: string;
  issuedAtTH?: string;
  issuedAtEN?: string;
  partnerId?: number;
  redeemDate?: string;
  reward?: Reward;
  expireBadge?: boolean;
  channeltag?: null | string;
  badge?: VoucherBadge;
  logo?: string;
  totalissued?: number;
  isShowButton?: boolean;
  voucherMessage?: string;
  voucherMessageBackgroungColor?: string;
  storeGroup?: string[];
  storeGroupText?: string;
  couponSource?: CouponSource;
  customContent?: CustomContent;
}
export class VoucherDetailConverter {
  public static fromJson(json: any): VoucherDetail {
    const model: VoucherDetail = {
      voucherCode: json['voucher_code'],
      voucherKey: json['voucher_key'],
      state: json['state'],
      reservedExpiresAt: json['reserved_expires_at'],
      voucherStartsAt: json['voucher_starts_at'],
      voucherExpiresAtTH: json['voucher_expires_at'],
      voucherExpiresAtEN: json['voucher_expires_at'],
      usedStoreName: json['used_store_name'],
      usedRef: json['used_ref'],
      usedDateTH: json['used_date'],
      usedDateEN: json['used_date'],
      posRef: json['pos_ref'],
      issuedAt: json['issued_at'],
      issuedAtTH: json['issued_at'],
      issuedAtEN: json['issued_at'],
      partnerId: json['partner_id'],
      redeemDate: json['redeem_date'],
      reward: json['reward']
        ? RewardConverter.fromJson(json['reward'])
        : undefined,
      expireBadge: json['expireBadge'],
      channeltag: json['channeltag'],
      badge: json['badge']
        ? VoucherBadgeConverter.fromJson(json['badge'])
        : undefined,
      logo: json['logo'],
      totalissued: json['totalissued'],
      isShowButton: json['showbutton'],
      voucherMessage: json['vouchermsg'],
      voucherMessageBackgroungColor: json['bgcolortag']?.toString().trim(),
      storeGroup: json['store_group'],
      storeGroupText: json['store_group_text'],
      couponSource: json['coupon_source'],
      customContent: json['customContent']
        ? CustomContentConverter.fromJson(json['customContent'])
        : undefined,
    };
    try {
      if (model.voucherExpiresAtTH && model.voucherExpiresAtEN) {
        const voucherExpiresDate = new Date(model.voucherExpiresAtTH);
        const hour = voucherExpiresDate.getHours().toString().padStart(2, '0');
        const minute = voucherExpiresDate
          .getMinutes()
          .toString()
          .padStart(2, '0');
        const formattedDateTH = `${voucherExpiresDate.getUTCDate()} ${
          shortMonth.th[voucherExpiresDate.getUTCMonth()]
        } ${voucherExpiresDate.getUTCFullYear()}${
          model.couponSource === CouponSource.camp ? ` ${hour}:${minute}` : ''
        }`;
        model.voucherExpiresAtTH = formattedDateTH;
        const formattedDateEN = `${voucherExpiresDate.getUTCDate()} ${
          shortMonth.en[voucherExpiresDate.getUTCMonth()]
        } ${voucherExpiresDate.getUTCFullYear()}${
          model.couponSource === CouponSource.camp ? ` ${hour}:${minute}` : ''
        }`;
        model.voucherExpiresAtEN = formattedDateEN;
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (model.issuedAtTH && model.issuedAtEN) {
        const voucherIssuedDate = new Date(model.issuedAtTH);
        const formattedDateTH = `${voucherIssuedDate.getUTCDate()} ${
          shortMonth.th[voucherIssuedDate.getUTCMonth()]
        } ${voucherIssuedDate.getUTCFullYear()}`;
        model.issuedAtTH = formattedDateTH;
        const formattedDateEN = `${voucherIssuedDate.getUTCDate()} ${
          shortMonth.en[voucherIssuedDate.getUTCMonth()]
        } ${voucherIssuedDate.getUTCFullYear()}`;
        model.issuedAtEN = formattedDateEN;
      }
    } catch (_) {}

    try {
      if (model.usedDateTH && model.usedDateEN) {
        const voucherExpiresDate = new Date(model.usedDateTH);
        const hour = voucherExpiresDate
          .getUTCHours()
          .toString()
          .padStart(2, '0');
        const minute = voucherExpiresDate
          .getMinutes()
          .toString()
          .padStart(2, '0');
        const formattedDateTH = `${voucherExpiresDate.getUTCDate()} ${
          shortMonth.th[voucherExpiresDate.getUTCMonth()]
        } ${voucherExpiresDate.getUTCFullYear()}${` ${hour}:${minute}`}`;
        model.usedDateTH = formattedDateTH;
        const formattedDateEN = `${voucherExpiresDate.getUTCDate()} ${
          shortMonth.en[voucherExpiresDate.getUTCMonth()]
        } ${voucherExpiresDate.getUTCFullYear()}${` ${hour}:${minute}`}`;
        model.usedDateEN = formattedDateEN;
      }
    } catch (e) {
      console.log(e);
    }
    try {
      if (model.issuedAt) {
        const voucherIssuedAt = new Date(model.issuedAt);
        const issueDate = voucherIssuedAt.getUTCDate();
        const issueMonth = voucherIssuedAt.getUTCMonth() + 1;
        model.issuedAt = `${issueDate < 10 ? `0${issueDate}` : issueDate}.${
          issueMonth < 10 ? `0${issueMonth}` : issueMonth
        }.${voucherIssuedAt.getUTCFullYear()}`;
      }
    } catch (_) {}
    return modelValue(model);
  }
}

export interface VoucherBadge {
  text?: string;
  color?: string;
  backgroundColor?: string;
}

export class VoucherBadgeConverter {
  public static fromJson(json: any): VoucherBadge {
    const model: VoucherBadge = {
      text: json['text'],
      color: json['color'],
      backgroundColor: json['background_color'],
    };
    return modelValue(model);
  }
}

export interface Reward {
  id?: number;
  rewardType?: string;
  status?: string;
  name?: string;
  displayName?: string;
  description?: string;
  shortDescription?: null | string;
  termsCondition?: string;
  couponConditionDescription?: null | string;
  image?: string;
  tags?: string;
  category?: number[];
  voucherCodeType?: string;
  amount?: number;
  discountType?: VoucherType;
  couponType?: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class MyVoucherModelConverter {
  public static fromJson(json: any): MyVoucherModel {
    const model: MyVoucherModel = {
      title: json['title'],
      sub: json['sub'],
      type: json['type'],
      state: json['state'],
      voucherdetail: json['voucherdetail']
        ? json['voucherdetail'].map((x: any) =>
            VoucherDetailConverter.fromJson(x),
          )
        : [],
      monththai: json['monththai'],
      productpaging: json['productpaging']
        ? ProductPagingConverter.fromJson(json['productpaging'])
        : undefined,
      shiprewardid: json['shiprewardid'],
      limit: json['limit'],
      page: json['page'],
      srct: json['srct'],
      sort: json['sort'],
      fill: json['fill'],
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
export class ProductPagingConverter {
  public static fromJson(json: any): Productpaging {
    const model: Productpaging = {
      total: json['total'],
      perPage: json['per_page'],
      currentPage: json['current_page'],
      lastPage: json['last_page'],
      from: json['from'],
      to: json['to'],
      skcount: json['skcount'],
    };
    return modelValue(model);
  }
}
export class RewardConverter {
  public static fromJson(json: any): Reward {
    const model: Reward = {
      id: json['id'],
      rewardType: json['reward_type'],
      status: json['status'],
      name: json['name'],
      displayName: json['display_name'],
      description: json['description'],
      shortDescription: json['short_description'],
      termsCondition: json['terms_condition'],
      couponConditionDescription: json['coupon_condition_description'],
      image: json['image'],
      tags: json['tags'],
      category: json['category'],
      voucherCodeType: json['voucher_code_type'],
      amount: json['amount'],
      discountType: json['discount_type'],
      couponType: json['coupon_type'],
    };
    return modelValue(model);
  }
}

export interface MyUsedVoucherListResponse {
  discountVoucher?: number;
  voucherUsedList?: VoucherUsed[];
}

export class MyUsedVoucherListResponseConverter {
  public static fromJson(json: any): MyUsedVoucherListResponse {
    const model: MyUsedVoucherListResponse = {
      discountVoucher: json['totaldisc']
        ? Number(
            json['totaldisc']
              .toString()
              .replaceAll(',', '')
              .replaceAll('฿', '')
              .trim(),
          )
        : undefined,
      voucherUsedList: json['itemlist']?.map((x: any) =>
        VoucherUsedConverter.fromJson(x),
      ),
    };
    return modelValue(model);
  }
}

export interface VoucherUsed {
  isError?: boolean;
  type?: string;
  key?: string;
  name?: string;
  voucherType?: string;
  discount?: string;
  maxDiscount?: string;
  isShippingVoucher?: boolean;
  voucherState?: string;
}

export class VoucherUsedConverter {
  public static fromJson(json: any): VoucherUsed {
    const model: VoucherUsed = {
      isError:
        json['is_error'] === true || json['is_error'] === 'true' ? true : false,
      type: json['type']?.toString()?.trim(),
      key: json['key']?.toString()?.trim(),
      name: json['name'],
      voucherType: json['voutype'],
      discount: json['discount'],
      maxDiscount: json['maxdisc']?.toString()?.trim(),
      isShippingVoucher:
        json['istransport'] == true || json['istransport'] === 'true'
          ? true
          : false,
      voucherState: json['state'],
    };
    return modelValue(model);
  }
}

export interface FreeList {
  quantity?: number;
  products?: ProductModel[];
}

export class FreeListConverter {
  public static fromJson(json: any): FreeList {
    const model: FreeList = {
      quantity: json['qnt'],
      products: json['products']
        ? json['products']?.map((x: any) => ProductModelConverter.fromJson(x))
        : [],
    };
    return modelValue(model);
  }
}

export interface CustomContent {
  couponId?: string;
  name?: string;
  imgPath?: string;
  description?: string;
  imageFullPath?: string;
}

export class CustomContentConverter {
  public static fromJson(json: any): CustomContent {
    const model: CustomContent = {
      couponId: json['couponId'],
      name: json['name'],
      imgPath: json['imgPath'],
      description: json['description'],
      imageFullPath: json['imageFullPath'],
    };
    return modelValue(model);
  }
}
