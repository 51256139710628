import { modelValue } from '@/utils/modelValue';
export interface CustomerInfo {
  custId?: string; // front-end undefined able
  email?: string;
  cname: string;
  t1c?: string;
  mobile?: string;
  rtoken?: string;
  rapptwdtoken?: string;
  rbnbtoken?: string;
  rappbnbtoken?: string;
  cartRef?: string;
  sumqnt?: number;
  salesource?: string;
  isFullMember: boolean;
  tiers?: Tier[];
  avatar?: string;
  isConsentPrivacy?: boolean;
  isConsentMarketing?: boolean;
}

export class CustomerInfoConverter {
  public static fromJson(json: any): CustomerInfo {
    const model: CustomerInfo = {
      custId: json['custid'] ?? '',
      email: json['email'],
      cname: json['cname'] ?? '',
      t1c: json['t1c'],
      mobile: json['mob'] ?? '',
      rtoken: json['RTOKEN'],
      rapptwdtoken: json['RAPPTWDTOKEN'],
      rbnbtoken: json['RBNBTOKEN'],
      rappbnbtoken: json['RAPPBNBTOKEN'],
      cartRef: json['CUSTREF'],
      sumqnt: json['SUMQNT'],
      salesource: json['salesource'],
      isFullMember: json['isFullMember'] == true ? true : false,
      tiers: json['tiers']?.map((e: any) => TierConverter.fromJson(e)),
      avatar: json['avatar'],
      isConsentPrivacy: json['isConsentPrivacy'] == true ? true : false,
      isConsentMarketing: json['isConsentMarketing'] == true ? true : false,
    };
    return modelValue(model);
  }
}
export interface Tier {
  code?: string;
  name?: string;
}

export class TierConverter {
  public static fromJson(json: any): Tier {
    const model: Tier = {
      code: json.code,
      name: json.name,
    };
    return modelValue(model);
  }
}

export interface CookieData {
  name: string;
  val?: string;
  days?: number | null;
  sameSite?: boolean | 'strict' | 'lax' | 'none';
  isRemove?: boolean;
}

export class CookieDataConverter {
  public static fromJson(json: any): CookieData {
    const model: CookieData = {
      name: json['name'],
      val: json['val']?.toString().trim(),
      days: json['days'],
    };
    return modelValue(model);
  }
}

export interface T1Error {
  code: ErrorCodes;
  message: string;
  subcode?: string;
}

export class T1ErrorConverter {
  public static fromJson(json: any): T1Error {
    const model: T1Error = {
      code: json['code'],
      message: json['message'],
      subcode: json['subcode'],
    };
    return modelValue(model);
  }
}

export enum ErrorCodes {
  // T1
  // eslint-disable-next-line no-unused-vars
  badRequest = 1002,
  // eslint-disable-next-line no-unused-vars
  tokenExpire = 1004,
  // Local
  // eslint-disable-next-line no-unused-vars
  unknownError = 0,
  // eslint-disable-next-line no-unused-vars
  connectionFailed = 100,
  // eslint-disable-next-line no-unused-vars
  consent = 101,
  // eslint-disable-next-line no-unused-vars
  userprofileFailed = 102,
  // eslint-disable-next-line no-unused-vars
  memberIdUndefined = 103,
  // eslint-disable-next-line no-unused-vars
  usernotfound = 104,
  // eslint-disable-next-line no-unused-vars
  createCustomerError = 105,
  // eslint-disable-next-line no-unused-vars
  existEmail = 1051,
  // eslint-disable-next-line no-unused-vars
  existMobile = 1052,
  // eslint-disable-next-line no-unused-vars
  ail = 1053,
  // eslint-disable-next-line no-unused-vars
  existVipId = 1054,
}

export enum LoginFail {
  // eslint-disable-next-line no-unused-vars
  system = 'system',
  // eslint-disable-next-line no-unused-vars
  state = 'state',
  // eslint-disable-next-line no-unused-vars
  storedState = 'storedState',
  // eslint-disable-next-line no-unused-vars
  invalid = 'invalid',
  // eslint-disable-next-line no-unused-vars
  consent = 'consent',
  // eslint-disable-next-line no-unused-vars
  code = 'code',
  // eslint-disable-next-line no-unused-vars
  invalidCsrf = 'invalidCsrf',
}

export interface IdentityResponse {
  info?: CustomerInfo;
  cookies?: CookieData[];
  error?: T1Error;
}
