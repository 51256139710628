import { modelValue } from '@/utils/modelValue';
import {
  CookieData,
  CookieDataConverter,
  CustomerInfo,
  CustomerInfoConverter,
} from '../Authen.model';
import { ProductModel, ProductModelConverter } from '../Product.model';
import { PreSum, PreSumConverter, ShippingType } from '../Checkout.model';
import {
  MyCurtainDetail,
  MyCurtainDetailConverter,
  PlppDetail,
  PlppDetailConverter,
} from '../MyCart.model';
import { Address, AddressConverter } from '../Address.model';
import { MyCampaign, MyCampaignConverter } from './Campaign.model';

export interface OrderhistoryDetailPageResponse {
  title?: string;
  sub?: string;
  isError?: boolean;
  canRefund?: boolean;
  the1Point?: string;
  ref?: string;
  stcode?: string;
  promotionDetail?: PlppDetail[];
  plppDetail?: PlppDetail[];
  orderDetail?: OrderDetail;
  packages?: Package[];
  preSum?: PreSum;
  vatShow?: string;
  addressList?: Address[];
  deliveryAddress?: Address;
  billAddress?: Address;
  hasIssueeTax?: boolean;
  orderCountDown?: Date;
  isRefundByPayment?: boolean;
  canRate?: boolean;
  isBanned?: boolean;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
  sumTotalShow?: string;
  shippingCostShow?: string;
  expressDeliveryFeeShow?: string;
  bulkyDeliveryFeeShow?: string;
  processingFeeShow?: string;
  paymentMethod?: { key: string; value?: string }[];
  pickupMethod?: { key: string; value?: string }[];
  grossPrice?: string;
  vatGtm?: string;
  newGtmItem?: any;
  isShowCampaignPopup?: boolean;
  sumCampaignWithMasterResult?: MyCampaign;
  hasAllPaint?: boolean;
  hasCurtain?: boolean;
  skuCurtainItems?: MyCurtainDetail[];
  sendEtaxVia?: string;
  cbWeb?: string;
  isPayBill?: boolean;
  isSplitedPackage?: boolean;
}
export class OrderhistoryDetailPageResponseConverter {
  public static fromJson(json: any): OrderhistoryDetailPageResponse {
    const model: OrderhistoryDetailPageResponse = {
      title: json['title'],
      sub: json['sub'],
      isError: json['iserror'],
      canRefund: json['canRefund'],
      ref: json['refshow'],
      stcode: json['stcodereturn'],
      promotionDetail:
        json['prodetail'] || json['plppdetail']
          ? (json['prodetail'] ?? json['plppdetail']).map((e: any) =>
              PlppDetailConverter.fromJson(e),
            )
          : undefined,
      plppDetail:
        json['prodetail'] || json['plppdetail']
          ? (json['prodetail'] ?? json['plppdetail']).map((e: any) =>
              PlppDetailConverter.fromJson(e),
            )
          : undefined,
      orderDetail: json['orderdetail']
        ? OrderDetailConverter.fromJson(json['orderdetail'])
        : undefined,
      packages: json['skuthankitem']?.map((e: any) =>
        PackageConverter.fromJson(e),
      ),
      preSum: json['ordertotal']
        ? PreSumConverter.fromJson(json['ordertotal'])
        : undefined,
      vatShow: json['vatgtm'],
      addressList: json['multiaddrs']
        ? json['multiaddrs'].map((x: any) => AddressConverter.fromJson(x))
        : undefined,
      deliveryAddress: json['stlocation']
        ? AddressConverter.fromJson(json['stlocation'])
        : undefined,
      billAddress: json['billaddr']
        ? AddressConverter.fromJson(json['billaddr'])
        : undefined,
      hasIssueeTax: json['hasissueetax'] === true ? true : false,
      the1Point: json['theonedatapoint'],
      orderCountDown: json['ordercountdown']
        ? new Date(json['ordercountdown'])
        : undefined,
      isRefundByPayment: json['isrefundbypayment'] === true ? true : false,
      canRate: json['canRate'] === true ? true : false,
      isBanned: json['isbanned'],
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
      sumTotalShow: (json['grossprice'] ?? '0')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      shippingCostShow: (json['shipshow'] ?? '0')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      expressDeliveryFeeShow: (json['samedayShipShow'] ?? '0')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      bulkyDeliveryFeeShow: (json['bulkyShipShow'] ?? '0')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      processingFeeShow: (json['feeshow'] ?? '0')
        .toString()
        .trim()
        .replace('฿', '')
        .trim()
        .replaceAll('.00', ''),
      paymentMethod: json['paymentmethod'],
      pickupMethod: json['pickupmethod'],
      grossPrice: json['grossprice'],
      vatGtm: json['vatgtm'],
      newGtmItem: json['newgtmitem'],
      isShowCampaignPopup: json['showcampaignpopup'] === true ? true : false,
      sumCampaignWithMasterResult: json['sumcampaignwithmasterresult']
        ? MyCampaignConverter.fromJson(json['sumcampaignwithmasterresult'])
        : undefined,
      hasAllPaint: json['hasallpaint'] === true ? true : false,
      hasCurtain: json['hascurtain'] === true ? true : false,
      skuCurtainItems: json['skucurtainitem']
        ? json['skucurtainitem'].map((x: any) =>
            MyCurtainDetailConverter.fromJson(x),
          )
        : [],
      sendEtaxVia:
        json['sendEtaxVia'] && json['sendEtaxVia'] !== ''
          ? json['sendEtaxVia']
          : undefined,
      cbWeb: json['cbweb'],
      isPayBill: json['isPayBill'] === true ? true : false,
      isSplitedPackage: json['isSplitedPackage'],
    };
    return modelValue(model);
  }
}

export interface OrderPaymentTotal {
  sumTotal?: number;
  sumPlpp: string;
  discountStaff?: string;
  discountVoucher?: string;
  shippingDiscount?: string;
  discountT1Point?: string;
  discountExpressFee?: string;
}

export class OrderPaymentTotalConverter {
  public static fromJson(json: any): OrderPaymentTotal {
    const model: OrderPaymentTotal = {
      sumTotal: json['amount'],
      sumPlpp: json['plppdisc'],
      discountStaff: json['discountstaff'],
      discountVoucher: json['cpndisc'],
      shippingDiscount: json['tspdisc'],
      discountT1Point: json['redeempaymentamount'],
      discountExpressFee: json['tspEXPdisc'],
    };
    return modelValue(model);
  }
}

export interface OrderDetail {
  orderDateShow?: string;
  paidDateShow?: string;
  statusCode?: string;
  statusName?: string;
  shippingCode?: string;
  shippingType?: ShippingType;
  storeName?: string;
  fullStoreName?: string;
  sendEtaxTo?: string;
  etaxStatus?: number;
}
export class OrderDetailConverter {
  public static fromJson(json: any): OrderDetail {
    const model: OrderDetail = {
      orderDateShow: json['#orderdate']?.replaceAll('.', ':'),
      paidDateShow: json['#paiddate']?.replaceAll('.', ':'),
      statusCode: json['#sorderstatus'],
      statusName: json['#sttname'],
      shippingCode: json['#dlvcode'],
      shippingType:
        json['#dlvcode'] === 'D' ? ShippingType.delivery : ShippingType.pickUp,
      storeName: json['#SName'],
      fullStoreName: json['#sorderstore'],
      sendEtaxTo: json['#sendetax'],
      etaxStatus: json['#pendingreqetax'],
    };
    return modelValue(model);
  }
}

export interface OrderhistoryListPageResponse {
  title?: string;
  sub?: string;
  limit?: number;
  page?: number;
  the1Point?: string;
  isBanned?: boolean;
  orders?: OrderHistory[];
  count?: number;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
}

export class OrderhistoryListPageResponseConverter {
  public static fromJson(json: any): OrderhistoryListPageResponse {
    const model: OrderhistoryListPageResponse = {
      title: json['title'],
      sub: json['sub'],
      limit: json['limit'],
      page: json['page'],
      the1Point: json['theonedatapoint'],
      isBanned: json['isbanned'],
      orders: json['listorders']
        ? json['listorders'].map((e: any) => OrderHistoryConverter.fromJson(e))
        : undefined,
      count: json['productpaging']?.skcount,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface OrderHistory {
  ref?: string;
  stcode?: string;
  orderDateShow?: string;
  nItem?: number;
  amountShow?: string;
  statusCode?: string;
  statusName?: string;
  shippingCode?: string;
  shippingType?: ShippingType;
  storeName?: string;
  fullStoreName?: string;
  packages?: Package[];
  onlyItemPackages?: Package[];
  canRate?: boolean;
  isSplitedPackage?: boolean;
}

export class OrderHistoryConverter {
  public static fromJson(json: any): OrderHistory {
    const model: OrderHistory = {
      ref: json['REF'],
      stcode: json['stcode'],
      orderDateShow: json['orderdate']?.replaceAll('.', ':'),
      nItem: json['nitem'],
      amountShow: json['amount']?.toString().trim().replace('.00', ''),
      statusCode: json['sostatus'],
      statusName: json['sostatusname'],
      shippingCode: json['dlvcode'],
      shippingType: json['dlvtype']?.toString().trim(),
      storeName: json['stsname'],
      fullStoreName: json['storename'],
      packages: json['SkuList']?.map((e: any) => PackageConverter.fromJson(e)),
      onlyItemPackages: json['SkuListOnlyItem']?.map((e: any) =>
        PackageConverter.fromJson(e),
      ),
      canRate: json['canRate'],
      isSplitedPackage: json['isSplitedPackage'],
    };
    return modelValue(model);
  }
}

export enum PickupLocation {
  // eslint-disable-next-line no-unused-vars
  front = 'F',
  // eslint-disable-next-line no-unused-vars
  back = 'B',
}

export interface Package {
  tracking?: Tracking;
  nItem?: number;
  qty?: number; // sumqty
  isLongtail?: boolean;
  products?: ProductModel[];
  bookingId?: string;
  pickupLocation?: PickupLocation;
  running?: number;
  trackingName?: string;
  trackingCode?: number;
  reScheduleData?: ReScheduleData;
  shippingName?: string;
  returnList: ProductModel[];
}

export class PackageConverter {
  public static fromJson(json: any): Package {
    const model: Package = {
      tracking: json['trackingNo']
        ? TrackingConverter.fromJson(json['trackingNo'])
        : undefined,
      nItem: json['nitems'],
      qty: json['sumqty'],
      isLongtail: json['longtail']?.toString().trim() === 'Y' ? true : false,
      products:
        json['sklist'] || json['skulist']
          ? (json['sklist'] ?? json['skulist'])?.map((e: any) =>
              ProductModelConverter.fromJson(e),
            )
          : undefined,
      bookingId: json['bookingid'],
      pickupLocation: json['pickupLocation']?.toString().trim(),
      running: json['sort'],
      trackingName: json['trackingName'],
      trackingCode: json['trackingCode'],
      reScheduleData: json['reScheduleData']
        ? ReScheduleDataConverter.fromJson(json['reScheduleData'])
        : undefined,
      shippingName: json['shipname'],
      returnList:
        json['returnlist'] || json['returnlist']
          ? (json['returnlist'] ?? json['returnlist'])?.map((e: any) =>
              ProductModelConverter.fromJson(e),
            )
          : [],
    };
    return modelValue(model);
  }
}

export interface ReScheduleData {
  oldDeliveryDate?: string;
  newDeliveryDate?: string;
  reason?: string;
}

export class ReScheduleDataConverter {
  public static fromJson(json: any): ReScheduleData {
    const model: ReScheduleData = {
      oldDeliveryDate: json['oldDeliveryDate'],
      newDeliveryDate: json['newDeliveryDate'],
      reason: json['reason'],
    };
    return modelValue(model);
  }
}

export interface Tracking {
  carrier?: string;
  trackingNumber?: string;
  deliveryDate?: string;
  useStock?: string;
}

export class TrackingConverter {
  public static fromJson(json: any): Tracking {
    const model: Tracking = {
      carrier: json['carrier'],
      trackingNumber: json['trackingNo'],
      deliveryDate: json['deliveryDate'] ?? json['pickupDate'],
      useStock: json['useStock'],
    };
    return modelValue(model);
  }
}

export interface CancelOrderResponse {
  isError?: boolean;
  message?: string;
  isVoidCoupon?: boolean;
}

export enum EtaxStatus {
  // eslint-disable-next-line no-unused-vars
  enableRequestAndDownload = 0,
  // eslint-disable-next-line no-unused-vars
  pending = 1,
  // eslint-disable-next-line no-unused-vars
  enableOnlyDownload = 2,
}

export interface ReOrderPageResponse {
  title?: string;
  isError?: boolean;
  canRefund?: boolean;
  ref?: string;
  info?: CustomerInfo | null;
  cookies?: CookieData[] | null;
  products?: ProductModel[];
}

export class ReOrderPageResponseConverter {
  public static fromJson(json: any): ReOrderPageResponse {
    const model: ReOrderPageResponse = {
      title: json['title'],
      isError: json['iserror'],
      canRefund: json['isrefund'],
      ref: json['reorder_refshow'],
      products: json['skuthankitems']
        ? json['skuthankitems']?.map((e: any) =>
            ProductModelConverter.fromJson(e),
          )
        : undefined,
      info: json['info']
        ? CustomerInfoConverter.fromJson(json['info'])
        : undefined,
      cookies: json['cookies']
        ? json['cookies'].map((x: any) => CookieDataConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}
